<!--
 * @Description: 商品详情页面组件
 * @Author: hai-27
 * @Date: 2020-02-16 20:20:26
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-07 21:59:26
 -->
<template>
  <div id="details" class="index">
    <!-- 主要内容 -->
    <div class="main">
      <!-- 左侧商品轮播图 -->
      <div class="block">
        <el-carousel height="560px">
          <el-carousel-item v-for="(item,index) in productPicture" :key="index">
            <img style="height:560px;" :src=" item" :alt="item.intro" />
          </el-carousel-item>
        </el-carousel>
        
      </div>
      <!-- 左侧商品轮播图END -->

      <!-- 右侧内容区 -->
      <div class="content">
        <h1 class="name">{{storeName}}</h1>
        <p class="intro">{{storeInfo}}</p>
        <!-- <p class="store">小米自营</p> -->
        <div class="price">
          <span>原价：{{productInfo.otPrice}}元</span>
          <span>库存{{productInfo.stock}}{{unitName}}</span>
          <span>销量{{totalSale}}{{unitName}}</span>
        </div>
        <div class="contentCenter">
          <div style="padding:10px 20px;margin-bottom:15px;background:rgb(243,243,243)">
            <div class="goodCount">
              <span class="span" v-if="vipLevel==1">大王尊享价</span>
              <span class="span" v-if="vipLevel==2">达人尊享</span>
              <span class="span" v-if="vipLevel==3">大户尊享</span>
              <span class="span" v-if="vipLevel==4">大咖尊享</span>
              <span class="span" v-if="vipLevel==5">大王尊享</span>
              <span style="fontSize:22px;color:red" v-if="vipLevel==1">{{productInfo.priceLevelFour}}元</span>
              <span style="fontSize:22px;color:red" v-if="vipLevel==2">{{productInfo.priceLevelOne}}元 </span>
              <span style="fontSize:22px;color:red" v-if="vipLevel==3">{{productInfo.priceLevelTwo}}元 </span>
              <span style="fontSize:22px;color:red" v-if="vipLevel==4">{{productInfo.priceLevelThree}}元</span>
              <span style="fontSize:22px;color:red" v-if="vipLevel==5">{{productInfo.priceLevelFour}}元 </span>
            </div>
            <!-- 属性 -->
            <div class="pro-list" v-for="(item,index) in productAttr" :key="index">
              <span class="prolistTitle">{{item.attrName}}：</span>
              <div class="prolistArr">
                <span v-for="(items,indexs) in item.attrValues" @click="handleAttr(items,index)" :key="indexs" :class="productAttrIndex.includes(items)?'activedAttr':''">
                  {{items}}
                </span>
              </div>
            </div>
          </div>
            <div class="descrition">
              服务说明：<img :src="require('@/assets/duihou.png')" alt=""> 一箱包邮 <img :src="require('@/assets/duihou.png')" alt="">由官方提供商品及7天无理由售后服务
            </div>
            <!-- 数量 -->
            <div class="goodCount">
              <span class="span">数量</span>
              <el-input-number v-model="goodCount"  :step="1" :min="1" :max="999" label="描述文字"></el-input-number> 
              <span style="padding: 0 15px">{{unitName}}</span> 
              <span style="padding: 0 15px">库存{{currentKuCun}}{{unitName}}</span>
            </div>
        </div>
        
        <!-- 内容区底部按钮 -->
        <div class="button">
          <el-button class="shop-cart" :disabled="dis" @click="addShoppingCart">加入购物车</el-button>
          <el-button class="like" v-if="currentKuCun>0" @click="addNewOrder" type="danger">直接购买</el-button>
          <el-button class="like" v-else @click="addNewOrder" type="danger">已售罄</el-button>
        </div>
      </div>
    </div>
    <!-- 主要内容END -->
    <div class="goodsInfoMenu">
        <div :class="infoIndex==0?'goodsInfoMenuItems activedItems':'goodsInfoMenuItems'" @click="changeIndex(0)">商品详情</div>
        <div :class="infoIndex==2?'goodsInfoMenuItems activedItems':'goodsInfoMenuItems'" @click="changeIndex(2)">包装与售后</div>
        <div :class="infoIndex==3?'goodsInfoMenuItems activedItems':'goodsInfoMenuItems'" @click="changeIndex(3)">用户评价({{replyCount}})</div>
    </div>
    <div class="goodsInfo" v-html="content" v-if="infoIndex == 0">

    </div>
    <!-- 售后 -->
    <!-- <div class="afterSale goodsInfoItem">
        <div v-if="replyList.length"></div>
        <div v-else></div>
    </div> -->
    <!-- 评价 -->
    <div class="reply goodsInfoItem goodsInfoItemRely">
        <div v-if="replyList.length">
          <el-row v-for="item in replyList" :key="item.id" class="goodsInfoItemRelyItem">
            <el-row v-if="item.pics">
              <el-col :span="6" v-for="(items,indexs) in item.pics" :key="indexs">
                <img :src="items" alt="">
              </el-col>
            </el-row>
            <el-col :span="24" class="comment">
              {{item.comment}}
            </el-col>
            <el-col :span="24" class="score">
              用户评分：{{item.score}}星
            </el-col>
            <el-col :span="24" class="info">
              <div>评论时间：{{item.createTime}}</div>
            </el-col>
            <el-col :span="24" class="infos">
              <img  :src="item.avatar" alt="">
              <div>昵称：{{item.nickname}}</div>
            </el-col>
          </el-row>

        </div>
        <div v-else></div>
    </div>
  </div>
</template>
<script>
import {apiGoodsDetail,apiAddCart,apiGoodsCommentCount,apiGoodsCommentList} from '@/api/goods'
import { aoiGetUserinfo } from "@/api/login";

export default {
  data() {
    return {
      dis: false, // 控制“加入购物车按钮是否可用”
      productID: "", // 商品id
      productInfo:{},
      productAttr: [],//商品规格
      productAttrIndex: [],
      productValue:{},
      productPicture: [],// 商品图片
      content:'',//商品明细长图
      goodCount: 1, //商品数量
      infoIndex: 0,
      replyCount: 0,
      replyList: [],
      vipLevel: 1,
      storeName: '',
      storeInfo: '',
      totalSale: 0,
      unitName: ''
    };
  },
  // 通过路由获取商品id
  mounted() {
    this.getUserInfo()
    if (this.$route.query.id != undefined) {
      this.productID = this.$route.query.id;
      this.getDetails(this.productID)
      this.getReplyCount(this.productID)
      this.getReplyList(this.productID)
    }
  },
  computed:{
    currentKuCun(){
        if(this.productValue&&this.productAttrIndex){
          return  this.productValue[this.productAttrIndex.join(',')]?this.productValue[this.productAttrIndex.join(',')].stock: 0
        }
        return 0
    }
  },
  methods: {
    getUserInfo() {
      aoiGetUserinfo().then((res) => {
        if (res.code == 200) {
          console.log(res);
          this.vipLevel = res.data.level
        }else{
          this.vipLevel = 1
        }
      });
    },
    //评论数量
    getReplyCount(val){
      apiGoodsCommentCount({id: val}).then(res=>{
        console.log(res);
        if(res.code == 200){
          this.replyCount = res.data.sumCount
        }
      })
    },
    //评论列表
    getReplyList(val){
      apiGoodsCommentList({id: val,page: 1,limit: 9999,type: 0}).then(res=>{
        console.log(res);
        if(res.code == 200){
          this.replyList = res.data.list
        }
      })
    },
    // 获取商品详细信息
    getDetails(val) {
      apiGoodsDetail({id: val,type: 'normal'}).then(res=>{
        console.log(res);
        if(res.code == 200){
          this.productPicture = res.data.productInfo.sliderImage ? JSON.parse(res.data.productInfo.sliderImage) :[]
          this.content = res.data.productInfo.content
          this.storeName = res.data.productInfo.storeName
          this.storeInfo = res.data.productInfo.storeInfo
          this.totalSale = res.data.productInfo.ficti+res.data.productInfo.sales
          this.unitName = res.data.productInfo.unitName
          this.productAttr = res.data.productAttr
          this.productValue = res.data.productValue
          this.productAttrIndex = []
          let arr = this.productAttr
          if(arr.length){
            arr.forEach(item=>{
              this.productAttrIndex.push(item.attrValues[0])
            })
          }
          this.productInfo  = this.productValue[this.productAttrIndex.join(',')]
         
        }
      })
    },
    //选择规格
    handleAttr(value,index){
      console.log(value);
      console.log(index);

      // this.productAttrIndex = value
      this.$set(this.productAttrIndex,index,value)
      console.log(this.productValue);
      this.productInfo  = this.productValue[this.productAttrIndex.join(',')]
    },
    // 获取商品图片
    getDetailsPicture(val) {
         console.log(val);
    },
    // 加入购物车
    addShoppingCart() {
      if(this.currentKuCun<1){
        this.$message.warning('商品已售罄')
        return false
      }
      let params = {
        cartNum: this.goodCount,
        isNew: false,
        productAttrUnique: this.productValue[this.productAttrIndex.join(',')].id,
        productId: this.productValue[this.productAttrIndex.join(',')].productId}
      apiAddCart(params).then(res=>{
        console.log(res);
        if(res.code == 200){
          this.$message.success('购物车添加成功')
        }else{
          this.$message.warning(res.message)
        }
      })
    },
    addNewOrder() {
      if(!localStorage.getItem('access_token')){
        this.$message.warning('请先登录')
        return false
      }
      let params = {
        preOrderType: "buyNow",
        orderDetails:[{attrValueId: this.productValue[this.productAttrIndex.join(',')].id, productId: this.productValue[this.productAttrIndex.join(',')].productId, productNum: this.goodCount}]
      }
      this.https.post('/api/front/order/pre/order',params).then(res=>{
        if(res.data.code == 200){
          this.$router.replace({path:'/createOrder',query:{
            orderNo: res.data.data.preOrderNo,
            preOrderType: "buyNow",
            params: JSON.stringify(params)
            }})
        }else{
          this.$message.warning(res.message)
        }
      })
    },
    //查看商品详情
    changeIndex(index){
      this.infoIndex = index
    }
  }
};
</script>
<style lang='scss' scoped>
.index{
  width: 1250px;
  margin: 0 auto;
  background-color: #fff;
}
.goodsInfo{
  margin-top: 50px;
  img{
    width: 100%!important;
  }
}


/* 主要内容CSS */
#details .main {
  width: 1225px;
  min-height: 620px;
  padding-top: 30px;
  margin: 0 auto;
}
#details .main .block {
  float: left;
  width: 560px;
  height: 560px;
}
#details .el-carousel .el-carousel__indicator .el-carousel__button {
  background-color: rgba(163, 163, 163, 0.8);
}
 .content {
  float: left;
  margin-left: 25px;
  width: 640px;
  text-align: left;
    .name {
      height: 30px;
      line-height: 30px;
      font-size: 24px;
      font-weight: normal;
      color: #212121;
    }
    .intro {
      color: #b0b0b0;
      padding-top: 10px;
    }
    .store {
      color: #ff6700;
      padding-top: 10px;
    }
    .price {
      display: flex;
      font-size: 14px;
      color: #ff6700;
      justify-content: flex-start;
      border-bottom: 1px solid #e0e0e0;
      padding: 25px 0 25px;
      span{
        margin-right: 40px;
      }
    }
    .contentCenter{
      min-height: 300px;
    }
    .price .del {
      font-size: 14px;
      margin-left: 10px;
      color: #b0b0b0;
      text-decoration: line-through;
    }
    .pro-list {
        display: flex;
        margin-bottom: 15px;
        .prolistTitle{
           width: 80px;
           height: 50px;
           line-height: 50px;
        }
        .prolistArr{
          line-height: 50px;
          align-items: center;
          display: flex;
          width: calc(100% - 100px);
          display: flex;
          justify-content: flex-start;
          span{
            margin-right: 15px;
            width: 100px;
            height: 30px;
            line-height: 30px;
            background-color: #ccc;
            text-align: center;
          }
        }
    }
    .goodCount{
      margin-top: 15px;
      line-height: 50px;
      display: flex;
      align-items: center;
      .span{
        width: 80px;
      }
    }
    .button {
      height: 55px;
      margin: 10px 0 20px 0;
    }
    .button .el-button {
      float: left;
      height: 55px;
      font-size: 16px;
      color: #fff;
      border: none;
      text-align: center;
    }
    .button .shop-cart {
      width: 340px;
      background-color: #ff6700;
    }
    .button .shop-cart:hover {
      background-color: #f25807;
    }

    .button .like {
      width: 260px;
      margin-left: 40px;
      // background-color: #b0b0b0;
    }
    // .button .like:hover {
    //   // background-color: #757575;
    // }
}
.goodsInfoMenu{
  width: 100%;
  border-top: 1px solid #ccc;
  margin-top: 15px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  .goodsInfoMenuItems {
    height: 26px;
    margin-right: 15px;
    font-size: 16px;
    color: #999;
    padding: 0 15px;
    cursor: pointer;
  }
  .activedItems{
   border-bottom: 1px solid orangered;
    color: orangered;
  }
}
.goodsInfoItem{
  min-height: 200px;
}
 .pro-policy li {
  float: left;
  margin-right: 20px;
  color: #b0b0b0;
}
.activedAttr{
  border:1px solid #ff6700;
  color: #f25807;
  background-color: #fff!important;
}
.descrition{
  line-height: 40px;
  display: flex;
  align-items: center;
  img{
    width: 18px;
    height: 18px;
    margin: 0 15px;
  }
}
.goodsInfoItemRely{
  width: 900px;
  margin: 0 auto;
  text-align: left;
  img{
    display: block;
    width: 132px;
    height: 132px;
  }
  .goodsInfoItemRelyItem{
    padding: 0 0 15px;
    border-bottom: 1px solid #f1f2f3;
    margin-bottom: 15px;
  }
  .comment,.score{
    line-height: 26px;
    font-size: 14px;
  }
  .info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  
  }
  .infos{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    img{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 20px;
    }
  }
}
</style>